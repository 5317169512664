.field {
    background: transparent;
    border: 1px solid;
    border-radius: $border-radius;
    padding: 0.5rem 1rem;
    transition: all 0.2s $smooth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    & input {
        width: 100%;
        outline: 0;
    }
}
input:placeholder {
    color: white;
}
