.articlePage {
  position: relative;
  margin-top: 300px;
  pointer-events: none;
  top: 0;
  & > * {
    pointer-events: all;
  }
}
.article__ingress {
  max-width: 700px;
  margin-bottom: 50px;
}
.article__ingress a {
  text-decoration: underline;
}
.article {
  max-width: 100%;
  &__title {
    max-width: 25ch;
  }
  &__content {
    & > * {
      max-width: 700px;
    }
    @include media-breakpoint-up(md) {
      width: max-content;
      margin: auto;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 60ch 60ch;
    }
  }
  & a {
    text-decoration: underline;
  }
  & p + h1,
  & p + h2,
  & p + h3 {
    margin-top: 2rem;
  }
  & h1,
  & h2,
  & h3 {
    margin-bottom: 1rem;
    grid-column: 1 / span 2;
  }
  &--left,
  &--right {
    grid-template-columns: 1fr;
    display: grid;
    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 1fr;
      display: grid;
      margin-top: 5rem;
      align-items: center;
      margin-bottom: 5rem;
      column-gap: 5rem;
      align-items: flex-start;
      justify-content: center;
    }
    @include media-breakpoint-up(xl) {
      grid-template-columns: 60ch 60ch;
    }
  }
  &--left &__content,
  &--right &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  &--left {
    & > * {
      order: 2;
    }
    & figure {
      order: 1;
    }
  }
  &--right {
    & figure {
      order: 2;
    }
  }
}
