.diagonal-wrapper {
  max-width: 700px;
  margin: auto;
  margin-bottom: 250px;
  margin-top: -450px;
  @media (max-width: 1900px) {
    margin-bottom: 150px;
  }
  display: flex;
  @include media-breakpoint-down(md) {
    max-width: 100vw;
    width: 100%;
  }
  &--bottom {
    max-width: 700px;
    margin-top: 100px;
    margin-bottom: -200px;
  }
}
.diagonal-box {
  margin: auto;
  padding: 4rem;
  display: inline-block;
  transform: rotate(-6deg);
  padding-top: 350px;
  & h1 {
    margin-bottom: 0;
    color: inherit;
  }
  &_title {
    font-size: 8rem;
    line-height: 8rem;
    @include media-breakpoint-down(sm) {
      transform: rotate(-6deg);
    }
  }
  &--bottom {
    padding-top: 4rem;
    padding-bottom: 300px;
    @include media-breakpoint-down(lg) {
      margin-top: 0;
    }
  }
}

@media (max-width: 900px) {
  .infopage__diagonal-box-header .diagonal-box {
    margin-top: 300px;
    padding-top: 100px;
    padding-bottom: 200px;
    margin-bottom: -250px;
    // aspect-ratio: 1 / 1;
    // mask-image: linear-gradient(35deg, #fff, transparent 70%);
  }
}
