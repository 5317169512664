.slider {
    // & > div {
    //     transition: none !important;
    // }
    & img {
        object-fit: cover;
    }
    @include media-breakpoint-down(sm) {
        &-control-centerright,
        &-control-centerleft {
            transform: scale(0.7) !important;
        }
        &-control-centerright {
            left: auto !important;
            right: -1rem !important;
        }
        &-control-centerleft {
            left: -1rem !important;
        }
    }
    &-frame {
        overflow: visible !important;
    }
}
.slider-slide {
    pointer-events: none;
    user-select: none;
}
.slide-visible,
.slide-current {
    pointer-events: all;
    user-select: text;
}
