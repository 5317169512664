/* .umb-image {
    animation: fade-in-img .3s cubic-bezier(0.215, 0.610, 0.355, 1) forwards;
}

@keyframes fade-in-img {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
} */

:root {
  --sharpen-k1: 0;
  --sharpen-k2: 1.5;
  --sharpen-k3: -0.5;
  --sharpen-k4: 0;
}

.umb-image img {
  filter: url(#f1);
  -webkit-filter: url(#f1);
  object-fit: cover;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

_::-webkit-full-page-media,
_:future,
:root .umb-image img {
  filter: none !important;
  -webkit-filter: none !important;
}

svg {
  -webkit-transform: translate3d(0, 0, 0);
}
