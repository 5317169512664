// Emoji font
@font-face {
  font-family: 'color-emoji';
  src: local('Apple Color Emoji'), local('Segoe UI Emoji'),
    local('Segoe UI Symbol'), local('Noto Color Emoji');
}
@import url('https://fonts.googleapis.com/css2?family=Karla:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
// Emojis
.emoji {
  font-family: 'color-emoji';
}

// Typography
body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  font-size: 18px;
}
.underlined {
  display: inline-block;
  padding-bottom: 1.5rem;
  border-bottom: solid 4px;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
b,
i {
  color: inherit;
}
h1 {
  color: white;
}
h1,
.h1 {
  font-size: 4rem;
  line-height: 4rem;
  &.low-line-height {
    line-height: 80%;
  }
  margin-block-end: 2rem;
  font-weight: bold;
  display: inline-block;
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
  &.small {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  &.large {
    font-size: 3rem;
    @include media-breakpoint-up(sm) {
      font-size: 7rem;
      line-height: 80%;
    }
  }
}
h2,
.h2 {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 2.1rem;
  // @include media-breakpoint-down(sm) {
  //     font-size: 1.15rem;
  // }
}
h2 {
  color: white;
}
h3 {
  color: white;
}
.wrap-text {
  & * {
    max-width: 100% !important;
    word-wrap: break-word;
  }
}
.date {
  font-weight: bold;
  // text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  text-transform: capitalize;
}
.h2 p {
  font-size: inherit;
}
.paragraph {
  max-width: 600px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
.h1,
.h2,
.h3,
.h4,
.h5,
.paragraph {
  @include media-breakpoint-down(sm) {
    max-width: none !important;
  }
}
.bo-colored {
  color: var(--bo);
}
.naering-colored {
  color: var(--naering);
}
.lev-colored {
  color: var(--lev);
}
