.header {
  padding: 2rem 2rem 1rem 2rem;
  max-width: 500px;
  margin-left: auto;
  margin-top: 1rem;
  @media (max-width: 800px) {
    margin-top: 30vh;
  }
  @media (min-width: 700px) {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 0.25rem;
    margin-right: 2rem;
  }
  &__arrow-container {
    max-width: 500px;
    margin-left: auto;
    text-align: center;
    margin: auto;
    margin-top: 1rem;
    @media (min-width: 700px) {
      margin-right: 2rem;
    }
  }
}
