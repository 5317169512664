.project-info {
    border-top: 1px solid;
    grid-gap: 2rem;
    @include media-breakpoint-down(sm) {
        border: none;
    }
    &__item {
        @include media-breakpoint-down(sm) {
            border-top: 1px solid;
            padding-top: 1rem;
            // width: 100%;
        }
    }
    &__image {
        @include media-breakpoint-up(xl) {
            margin-top: -200px !important;
        }
    }
}

.badge {
    font-weight: normal;
    color: black;
    &--planleggingfase,
    &--underutvikling,
    &--snartklar {
        background: tint(orange, 50%);
        & svg {
            fill: orange;
        }
    }
    &--klar {
        background: tint(green, 50%);
        & svg {
            fill: green;
        }
    }
}
.cube-title h1 {
    max-width: 8ch;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-wrap: break-word;
    @include media-breakpoint-down(sm) {
        font-size: 2rem;
    }
}
