// Colors
$primary: white;
$dark: #2e2e2e;
// $primaryDark: #310b08;
// $primaryLight: #ffdfdc;
// $secondary: #394bf1;
// $secondaryLight: #f5d3ff;
// $secondaryDark: #3a0933;
// $primaryGradient: linear-gradient(-45deg, $secondary 0%, $primary 100%);

// Borders
$border-width: 0.1rem;
$border-radius: 0.3rem;

$smooth: cubic-bezier(0.22, 1, 0.36, 1);

$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 992px,
    lg: 1500px,
    xl: 2000px,
) !default;

$container-max-widths: (
    sm: 768px,
    md: 992px,
    lg: 1700px,
    xl: 2000px,
) !default;

// Typography
// $font-size-base: 1rem;
// @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

// $font-family-sans-serif: "Nunito", system-ui, BlinkMacSystemFont, "Segoe UI",
//     Roboto, "Helvetica Neue", Arial, sans-serif !default;
// $font-family-serif: Georgia, "Times New Roman", Times, serif !default;
// $font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
//     "Courier New", monospace !default;
// $font-family-base: $font-family-sans-serif !default;
