body {
  background: rgb(51, 51, 51);
  background: linear-gradient(
    139deg,
    rgba(51, 51, 51, 1) 0%,
    rgba(29, 29, 29, 1) 25%
  );
}
#root {
  position: relative;
  overflow: hidden;
  height: auto;
  max-width: 100vw;
}
#content {
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
}
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}
::-webkit-scrollbar-button {
  background-color: #666;
}
::-webkit-scrollbar-track {
  background-color: #646464;
}
::-webkit-scrollbar-track-piece {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
::-webkit-scrollbar-corner {
  background-color: #646464;
}
::-webkit-resizer {
  background-color: #666;
}
