.status-range {
    width: 100%;
    max-width: 800px;
    @include media-breakpoint-down(sm) {
        width: 500px;
        transform: rotate(90deg) translateY(100px);
        margin-bottom: 100px;
        margin-top: 200px;
        transform-origin: top;
    }
}
.status-range-grid {
    border-bottom: 0.2rem solid grey;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 3rem;
    text-align: center;
    position: relative;
    @include media-breakpoint-down(sm) {
        background: shade(rgb(54, 54, 54), 40%);
        padding-left: 2rem;
    }
    &_label {
        font-weight: bold;
        color: white;
        width: min-content;
        margin: auto;
        font-size: 1rem;
        @include media-breakpoint-down(sm) {
            transform-origin: 1rem;
            transform: rotate(-90deg);
        }
        &:first-of-type {
            margin-right: auto;
            margin-left: 0;
        }
        &:last-of-type {
            margin-left: auto;
            margin-right: 0;
        }
        &--current {
            color: black;
        }
        // &.status-range_label--1.status-range_label--current {
        //     background: var(--bo);
        //     color: black;
        // }
    }
    &_point {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 100%;
        transform: translateY(1rem);
        font-weight: bold;
        grid-row: 3;
        &--1 {
            grid-column: 1;
            margin-right: auto;
        }
        &--2 {
            grid-column: 2;
            margin: auto;
            @include media-breakpoint-down(sm) {
                margin-right: auto;
            }
        }
        &--3 {
            grid-column: 3;
            margin: auto;
            @include media-breakpoint-down(sm) {
                margin-right: auto;
            }
        }
        &--4 {
            grid-column: 4;
            margin-left: auto;
            @include media-breakpoint-down(sm) {
                margin-right: auto;
            }
        }
    }
    &_start,
    &_end {
        display: inline-block;
        margin-top: 1rem;
    }
    &_start {
        grid-column: 1;
        grid-row: 2;
        margin-right: auto;
    }
    &_end {
        grid-column: 4;
        grid-row: 2;
        margin-left: auto;
    }
}
