.projects {
  &__item {
    display: grid;
    padding: 0 0.5rem;
    &--thumbnails {
      grid-template-columns: 1fr 6fr;
      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr 4fr;
      }
    }
    width: 100%;
    margin-bottom: 1rem;
    grid-gap: 0.5rem;
    &__thumb {
      pointer-events: none;
      height: 80px;
      object-fit: cover;
      display: block;
      width: 100%;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &__btn {
      pointer-events: none;
      width: 100%;
      font-size: 1.25rem;
      transform-origin: left;
      text-align: left;
      padding: 0.15rem 1rem !important;
    }
    & svg {
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      -webkit-font-smoothing: antialised;
      backface-visibility: hidden;
      transform: translateZ(0) scale(0.5);
      font-size: 3rem;
      -webkit-font-smoothing: subpixel-antialiased;
      transform-origin: center right;
    }
    &:hover {
      & img {
        transform: scale(1.2);
      }
      & .btn {
        transform: scale(1.02);
        & svg {
          -webkit-font-smoothing: antialised;
          backface-visibility: hidden;
          transform: translateZ(0) scale(0.8);
          -webkit-font-smoothing: subpixel-antialiased;
        }
      }
    }
    &--thumbnails-on-top {
      display: block;
      padding: 0.5rem;
      margin-bottom: 0;
      & .btn {
        transform-origin: center;
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-top: 0 !important;
      }
      &:hover {
        & .btn {
          transform: scale(1.05);
        }
        & img {
          transform: scale(1.05);
        }
      }
      & img {
        // margin-bottom: 0.5rem;
        height: 230px;
        object-fit: cover;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        // min-height: 150px;
        // height: auto;
        // max-height: 300px;
      }
    }
  }
}
.venue {
  @media screen and (min-width: 1200px) {
    padding-top: 100px;
  }
}

#projects-search::placeholder {
  color: inherit;
  opacity: 0.7;
}
