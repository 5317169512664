.logo {
    transition: all 0.5s $smooth;
    z-index: 2;
    position: relative;
    pointer-events: all;
    position: absolute;
    top: 1rem;
    right: 1rem;
    @include media-breakpoint-only(xs) {
        right: 0;
        top: 0;
        transform-origin: right;
        transform: scale(0.8);
    }
    &:hover {
        transform: scale(1.1);
    }
}
