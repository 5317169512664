// CUBE
.above-cubes {
    z-index: 50;
    position: relative;
}
.cube-tooltip {
    background: white;
    color: $dark;
    position: absolute;
    z-index: 2;
    width: 300px;
    // box-shadow: 10px 10px 50px black;
    top: 2rem;
    left: -0.8rem;
    padding: 0.5rem;
    opacity: 0;
    pointer-events: none;
    transition: display 0s;
    transform-origin: top left;
    transform: translateZ(0) scale(1.5, 1.5) translate(-7%, 10%);
    // transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 1.5rem;
        border-style: solid;
        border-color: transparent transparent #ffffff transparent;
        bottom: 99%;
        left: 0;
    }
    &-show {
        opacity: 1;
        pointer-events: all;
        &:hover {
            opacity: 1;
            pointer-events: all;
        }
    }
    & > *:last-of-type {
        margin-bottom: 0;
    }
    &-right {
        right: 0;
    }
}
.cube-container {
    &:hover {
        z-index: 100 !important;
    }
    & > a {
        display: block;
        transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);
        // &:hover {
        //     transform: scale(1.1) !important;
        // }
    }
}
.cube-link {
    &:hover {
        & .cube-tooltip {
            opacity: 1;
            display: block;
            transition-delay: 0.25s;
        }
    }
}
.cube {
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-origin: bottom;
    transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
    &:hover {
        & .cube-tooltip {
            opacity: 1;
            display: block;
            transition-delay: 0.25s;
            pointer-events: none;
            user-select: none;
        }
    }
    & img {
        user-select: none;
        pointer-events: none;
    }
    & * {
        user-select: none;
        backface-visibility: hidden;
    }
    &__face {
        position: absolute;
        pointer-events: inherit;
        transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        overflow: hidden;
        & img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__face--front {
        filter: brightness(110%);
        &--text:before {
            content: "front";
            position: absolute;
        }
    }
    &__face--right {
        transform-origin: right;
        filter: brightness(90%);
        &--text:before {
            content: "right";
            position: absolute;
        }
    }
    &__face--back {
        &--text:before {
            content: "back";
            position: absolute;
        }
    }
    &__face--left {
        transform-origin: left;
        &--text:before {
            content: "left";
            position: absolute;
        }
    }
    &__face--top {
        filter: brightness(100%);
        transform-origin: top;
        &--text:before {
            content: "top";
            position: absolute;
        }
    }
    &__face--bottom {
        transform-origin: bottom;
        &--text:before {
            content: "bottom";
            position: absolute;
        }
    }

    &:hover {
        & * {
            cursor: pointer;
            opacity: 0.8;
        }
        & *:not(.cube__face--front) {
            // box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
        }
    }
}

@keyframes rotation {
    0% {
        transform: rotate3d(1, 1, 1, 0deg);
    }
    100% {
        transform: rotate3d(1, 1, 1, 360deg);
    }
}

@keyframes scaleY {
    0% {
        transform: rotateX(-25deg) rotateY(75deg) scale3d(0.5, 0.5, 0.5)
            translateY(100px);
        transform-origin: bottom;
    }
    100% {
        transform: rotateX(-25deg) rotateY(75deg) scale3d(0.5, 1, 0.5)
            translateY(100px);
        transform-origin: bottom;
    }
}
