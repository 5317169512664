@include media-breakpoint-up(xs) {
    @for $i from 0 through 10 {
        .rounded-#{$i} {
            border-radius: #{$i/2}rem;
        }
        .rounded-tl-#{$i} {
            border-top-left-radius: #{$i/2}rem;
        }
        .rounded-tr-#{$i} {
            border-top-right-radius: #{$i/2}rem;
        }
        .rounded-bl-#{$i} {
            border-bottom-left-radius: #{$i/2}rem;
        }
        .rounded-br-#{$i} {
            border-bottom-right-radius: #{$i/2}rem;
        }
    }
}
@include media-breakpoint-up(sm) {
    @for $i from 0 through 10 {
        .rounded-sm-#{$i} {
            border-radius: #{$i/2}rem;
        }
        .rounded-tl-sm-#{$i} {
            border-top-left-radius: #{$i/2}rem;
        }
        .rounded-tr-sm-#{$i} {
            border-top-right-radius: #{$i/2}rem;
        }
        .rounded-bl-sm-#{$i} {
            border-bottom-left-radius: #{$i/2}rem;
        }
        .rounded-br-sm-#{$i} {
            border-bottom-right-radius: #{$i/2}rem;
        }
    }
}
@include media-breakpoint-up(md) {
    @for $i from 0 through 10 {
        .rounded-md-#{$i} {
            border-radius: #{$i/2}rem;
        }
        .rounded-tl-md-#{$i} {
            border-top-left-radius: #{$i/2}rem;
        }
        .rounded-tr-md-#{$i} {
            border-top-right-radius: #{$i/2}rem;
        }
        .rounded-bl-md-#{$i} {
            border-bottom-left-radius: #{$i/2}rem;
        }
        .rounded-br-md-#{$i} {
            border-bottom-right-radius: #{$i/2}rem;
        }
    }
}
@include media-breakpoint-up(lg) {
    @for $i from 0 through 10 {
        .rounded-lg-#{$i} {
            border-radius: #{$i/2}rem;
        }
        .rounded-tl-lg-#{$i} {
            border-top-left-radius: #{$i/2}rem;
        }
        .rounded-tr-lg-#{$i} {
            border-top-right-radius: #{$i/2}rem;
        }
        .rounded-bl-lg-#{$i} {
            border-bottom-left-radius: #{$i/2}rem;
        }
        .rounded-br-lg-#{$i} {
            border-bottom-right-radius: #{$i/2}rem;
        }
    }
}
@include media-breakpoint-up(xl) {
    @for $i from 0 through 10 {
        .rounded-xl-#{$i} {
            border-radius: #{$i/2}rem;
        }
        .rounded-tl-xl-#{$i} {
            border-top-left-radius: #{$i/2}rem;
        }
        .rounded-tr-xl-#{$i} {
            border-top-right-radius: #{$i/2}rem;
        }
        .rounded-bl-xl-#{$i} {
            border-bottom-left-radius: #{$i/2}rem;
        }
        .rounded-br-xl-#{$i} {
            border-bottom-right-radius: #{$i/2}rem;
        }
    }
}

.opacity-0 {
    opacity: 0;
}

.opacity-10 {
    opacity: 0.1;
}

.opacity-20 {
    opacity: 0.2;
}

.opacity-30 {
    opacity: 0.3;
}

.opacity-40 {
    opacity: 0.4;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-60 {
    opacity: 0.6;
}

.opacity-70 {
    opacity: 0.7;
}

.opacity-80 {
    opacity: 0.8;
}

.opacity-90 {
    opacity: 0.9;
}

.opacity-100 {
    opacity: 1;
}
.no-interaction {
    pointer-events: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}
.no-container-interaction {
    pointer-events: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    & > * {
        pointer-events: all;
        -webkit-user-drag: all;
        -khtml-user-drag: all;
        -moz-user-drag: all;
        -o-user-drag: all;
        user-drag: all;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -o-user-select: text;
        user-select: text;
    }
}
.shadow {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5) !important;
}
.mask-bottom {
    -webkit-mask-image: -webkit-gradient(
        linear,
        left 60%,
        left 95%,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
    );
}
.object-fit-cover {
    object-fit: cover;
}
