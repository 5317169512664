.dynamic-left-section {
  @include media-breakpoint-up(lg) {
  }
}
.dynamic-right-section {
  @include media-breakpoint-up(lg) {
  }
}
.dynamic-page__container {
  padding-top: 500px;
}

.dynamic-page__container--infopage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
