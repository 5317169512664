.footer {
    padding: 5rem;
    padding-bottom: 150px;
    width: 100%;
    // border-top: 1px solid rgba(255, 255, 255, 0.1);
    // margin-top: 200px;
    @include media-breakpoint-down(sm) {
        margin-top: 50px;
        padding: 2rem;
        padding-bottom: 12rem;
    }
    & .logo {
        position: relative;
        margin-left: auto;
        @include media-breakpoint-down(sm) {
            margin: auto;

            margin-top: 50px;
            transform: none;
            transform-origin: center;
        }
    }
}
