.colorbox {
    padding: 1rem 1rem 2rem 2rem;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    text-decoration: none;
    transition: all 0.17s cubic-bezier(0.5, 1, 0.89, 1);
    position: relative;
    width: 100%;
    box-sizing: content-box;
    @include media-breakpoint-up(sm) {
        width: 350px;
        height: 350px;
    }
    &:hover {
        transform: rotate(0deg) scale(1.05);
        z-index: 3;
    }
    // & h1 {
    //     font-size: 5rem;
    //     line-height: 5rem;
    //     margin-bottom: 1rem;
    //     @include media-breakpoint-down(sm) {
    //         font-size: 3rem;
    //         margin-bottom: 0;
    //         line-height: 3rem;
    //     }
    // }
    & h1 {
        margin-bottom: 1rem;
        color: inherit;
    }
    & p {
        max-width: 27ch;
    }
    &-rotated {
        transform: rotate(-10deg);
    }
    // &-not-current {
    //     opacity: 0.5;
    // }
    // &-current {
    //     opacity: 1;
    // }
    &-hover {
        &:not(:hover) {
            filter: brightness(50%);
        }
    }
    &_icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 4rem;
    }
}
