#letter-vivus {
    & svg {
        overflow: visible;
        max-width: 300px;
        // & path {
        //     stroke-width: 3rem;
        //     animation: showLetter 1s forwards $smooth 0.3s;
        // }
        & linearGradient > stop:nth-of-type(2) {
            stop-opacity: 0.5;
        }
    }
}
.letter {
    & h1 {
        @include media-breakpoint-down(sm) {
            font-size: 2rem;
        }
    }
}
