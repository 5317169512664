button:focus {
  outline: 0;
  box-shadow: none;
}
.hover-scale {
  display: inline-block;
  transform: translateZ(0) scale(0.97);
  transition: all 0.15s ease-in-out;
  &:hover {
    transform: scale(1);
  }
}
.btn {
  transform: translateZ(0);
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-font-smoothing: subpixel-antialiased;
  transform: perspective(1px) translateZ(0) scale(1);
  backface-visibility: hidden;
  display: inline-block;
  user-select: text;
  &:hover {
    opacity: 0.9;
    transform: perspective(1px) translateZ(0) scale(1.01);
    filter: brightness(120%);
    text-decoration: underline;
  }
  &-lg {
    font-size: 2rem;
  }
  &.link {
    text-decoration: underline;
    line-height: 110%;
  }
  &--transparent {
    background: transparent !important;
    &:hover {
      filter: brightness(110%);
      background: rgba(0, 0, 0, 0.2) !important;
    }
  }
}
input {
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
input:hover {
  transform: perspective(1px) translateZ(0) scale(1.05);
  filter: brightness(120%);
}
// .btn-transparent {
//     /* bg, border, hover bg, hover border*/
//     @include button-variant(
//         transparent,
//         transparent,
//         rgba(200, 200, 200, 0.2),
//         transparent
//     );
//     color: $body-color;
//     &-primary {
//         @extend .btn-transparent;
//         color: $primary;
//         background-origin: border-box;
//         background-clip: content-box, border-box;
//         background: transparent;
//         &:hover {
//             border-color: $primary;
//             background: transparent;
//         }
//     }
//     &-secondary {
//         @extend .btn-transparent;
//         @include button-variant(
//             transparent,
//             transparent,
//             $secondary,
//             transparent
//         );
//         color: $secondary;
//     }
//     &-grey {
//         @extend .btn-transparent;
//         @include button-variant(transparent, transparent, grey, transparent);
//         color: grey;
//     }
// }
// .btn-light {
//     @include button-variant($body-bg, transparent);
// }
