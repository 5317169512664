body.noscroll {
  overflow: hidden !important;
  height: 100vh;
  padding-right: 8px;
  margin-left: calc(100vw - 100%);
}
.nav {
  position: absolute;
  z-index: 99;
  pointer-events: none;

  @include media-breakpoint-down(sm) {
    min-height: 100vh;
    margin-bottom: -55vh;
  }
  &-open {
    pointer-events: all;
  }
  &-expanded {
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
    padding-bottom: 5rem;
    @include media-breakpoint-up(md) {
      padding-right: 5rem;
      padding-top: 3rem;
    }
    width: 100vw;
  }
  &_back {
    pointer-events: all;
    position: relative;
    @media (max-width: 1000px) {
      left: 2rem;
      margin: 0;
      top: 2rem;
      position: absolute;
    }
    z-index: 52;
  }
  & .colorbox {
    height: auto;
    width: auto;
    padding: 1.5rem;
    padding-right: 5rem;
    &:hover {
      transition: opacity 0.1s cubic-bezier(0.165, 0.84, 0.44, 1),
        transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    & > * {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &-small {
      & h1 {
        font-size: 2rem;
      }
    }
    &_icon {
      top: 50%;
      transform: translateY(-50%);
      @include media-breakpoint-down(sm) {
        font-size: 2.5rem;
        line-height: 2rem;
      }
    }
    & h1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
        line-height: 2rem;
        margin-bottom: 0;
      }
    }
  }
  &-hamburger {
    display: flex;
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    width: 100vw;
    z-index: 51;
    &_menu {
      pointer-events: all;
      position: relative;
      z-index: 51;
      display: flex;
      justify-content: space-between;
      transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
      @include media-breakpoint-down(sm) {
        width: 100%;
        height: 100%;
      }
      &:hover {
        opacity: 0.8;
      }
      &_toggle {
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        & button {
          outline: none !important;
          display: flex;
          justify-content: center;
        }
        &_btn {
          outline: none !important;
          padding: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 1rem;
          @include media-breakpoint-down(sm) {
            padding: 1rem;
            width: 100%;
            top: auto;
            position: fixed;
            bottom: 0;
            z-index: 52;
            background: black;
            transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
            &--hide {
              transform: translateY(100%);
            }
            &::before {
              content: 'MENY ';
            }
          }
        }
      }
    }
    &_submenu {
      position: fixed;
      @include media-breakpoint-down(sm) {
        z-index: 50;
      }
      top: 0;
      left: 0;
      width: 100%;
      overflow-y: scroll;
      opacity: 0;
      display: block;
      &::before {
        content: '';
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: rgba(shade($dark, 50%), 0.9);
        opacity: 0;
        transition: opacity 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      &--open {
        opacity: 1;
        &::before {
          opacity: 1;
        }
        // @include media-breakpoint-up(md) {
        //     backdrop-filter: blur(10px);
        //     -webkit-backdrop-filter: blur(10px);
        // }
      }
      &_content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 100px 0 200px 0;
        min-height: 100vh;
        @include media-breakpoint-down(sm) {
          justify-content: flex-start;
          padding-bottom: 300px;
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .nav_back {
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    & span {
      display: inline-block;
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
    &--hide {
      transform: translateX(-80%);
      & span {
        width: 0;
        opacity: 0;
      }
    }
  }
}

.nav-back-wrapper {
  pointer-events: none;
  @media (min-width: 1001px) {
    position: absolute;
    top: 400px;
    left: 0;
    right: 0;
    max-width: min(2000px, 90%);
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-start;
  }
  @media (max-width: 1000px) {
    padding: 0;
    margin: 0;
  }
}
