.posts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
  }
  grid-gap: 2rem;
}
.posts-nogrid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.post {
  display: grid;
  align-items: stretch;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr;
  }
  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr 1fr;
  }
  // @include media-breakpoint-down(md) {
  //     grid-template-columns: 1fr 2fr;
  // }
  @include media-breakpoint-down(sm) {
    display: block;
  }
  &-category {
    grid-column: 1 / span 3;
    @media screen and (max-width: 1500px) {
      grid-column: 1 / span 2;
    }
  }
  &--featured {
    grid-template-columns: 2fr 1fr;
    grid-column: 1 / span 3;
    & .post_content {
      padding: 4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & a:hover .post_content_title {
        text-decoration: underline;
      }
    }
    & .post_thumbnail {
      height: 600px;

      @include media-breakpoint-down(sm) {
        height: 100%;
        max-height: 400px;
      }
    }
    @media screen and (max-width: 1500px) {
      grid-template-columns: 1fr 1fr;
      grid-column: 1 / span 2;
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr 1fr;
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      & .post_content {
        padding: 2rem;
      }
    }
  }
  &_thumbnail {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    will-change: transform;
    // height: 500px;
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    @include media-breakpoint-down(sm) {
      height: auto;
      height: 100%;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
  &_content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-down(sm) {
      & .btn {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    // &_title {
    // }
    // &_text {
    // }
  }
}
.post_content_title {
  display: block;
}
