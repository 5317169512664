.map-container {
  position: relative;
  transform-origin: center;
  left: 0;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: translate(-300px, 80px) scale(0.9);
  @media screen and (max-width: 2400px) {
    transform: translate(-250px, 80px) scale(0.85);
  }
  @media screen and (max-width: 2200px) {
    transform: translate(-200px, 80px) scale(0.8);
  }
  @media screen and (max-width: 2000px) {
    transform: translate(-250px, 80px) scale(0.7);
  }
  @media screen and (max-width: 1900px) {
    transform: translate(-220px, 250px) scale(0.65);
  }
  @media screen and (max-width: 1700px) {
    transform: translate(-220px, 350px) scale(0.55);
  }
  @media screen and (max-width: 1600px) {
    transform: translate(-220px, 350px) scale(0.5);
  }
  @media screen and (max-width: 1500px) {
    transform: translate(80px, -80px) scale(0.5);
  }
  @media screen and (max-width: 1200px) {
    transform: translate(0px, -80px) scale(0.5);
  }
  @media screen and (max-width: 1000px) {
    transform: translate(0px, 0px) scale(0.4);
  }
  @media screen and (max-width: 800px) {
    transform: translate(15%, -10%) scale(0.6);
  }
  @media screen and (max-width: 650px) {
    transform: translate(20%, -10%) scale(0.6);
  }
}
.map-center {
  left: 50%;
  transform: translate(-50%, -400px);
  position: absolute;
  & + * {
    padding-top: 230px;
    @include media-breakpoint-down(sm) {
      padding-top: 300px;
    }
  }

  &--nothome {
    & + *,
    & + .animate-mount > * {
      padding-top: 300px;
      @media screen and (max-width: 1900px) {
        padding-top: 400px;
      }
    }
    & .map-container {
      transform: translate(-400px, -150px) scale(0.7);
      @media screen and (max-width: 2200px) {
        transform: translate(-400px, -80px) scale(0.6);
      }
      @media screen and (max-width: 2000px) {
        transform: translate(-360px, -30px) scale(0.6);
      }
      @media screen and (max-width: 1900px) {
        transform: translate(-300px, 0px) scale(0.5);
      }
      @media screen and (max-width: 1600px) {
        transform: translate(-300px, 100px) scale(0.45);
      }
      @media screen and (max-width: 1500px) {
        transform: translate(50px, -100px) scale(0.6);
      }
      @media screen and (max-width: 1200px) {
        transform: translate(20px, 0px) scale(0.5);
      }
      @media screen and (max-width: 1000px) {
        transform: translate(70px, 70px) scale(0.45);
      }
      @media screen and (max-width: 800px) {
        transform: translate(70px, 130px) scale(0.4);
      }
    }
  }
  &--projects {
    & + * {
      padding-top: 500px;
      @media screen and (max-width: 1900px) {
        padding-top: 450px;
      }
    }
    & .map-container {
      transform: translate(-400px, -200px) scale(0.7);
      @media screen and (max-width: 2200px) {
        transform: translate(-350px, -100px) scale(0.6);
      }
      @media screen and (max-width: 2000px) {
        transform: translate(-160px, -100px) scale(0.6);
      }
      @media screen and (max-width: 1700px) {
        transform: translate(0px, -140px) scale(0.6);
      }
      @media screen and (max-width: 1200px) {
        transform: translate(30px, 0px) scale(0.45);
      }
      @media screen and (max-width: 900px) {
        transform: translate(60px, 50px) scale(0.4);
      }
      @media screen and (max-width: 700px) {
        transform: translate(80px, 120px) scale(0.35);
      }
      @media screen and (max-width: 600px) {
        transform: translate(45px, 250px) scale(0.22);
      }
    }
  }
  &--info {
    & .map-container {
      transform: translate(-40%, 0px) scale(0.6) rotateX(35deg) rotateZ(-15deg);
    }
  }
}
.falldown {
  opacity: 0;
  transform: translateY(-50px);
  animation: fallDown 1s forwards $smooth 0.3s;
}
.map-intro {
  position: relative;
  transform: translateY(500px) scale(0.7) translateX(-50px);
  @media screen and (max-width: 1800px) {
    transform: translateY(300px) scale(0.7) translateX(-50px);
  }
  @media screen and (max-width: 1500px) {
    transform: translateY(300px) scale(0.7) translateX(-50%) translateY(500px);
  }
}
.map {
  transform-origin: center;
  transform: scale(4.131);
}
@keyframes intro {
  0% {
    opacity: 0;
    transform: rotateX(15deg) rotateY(0) rotateZ(0) scale(2.2) translateY(300px);
  }
  100% {
    transform: rotateX(0) rotateY(0) rotateZ(0) scale(2) translateY(300px);
  }
}
@keyframes fallDown {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
