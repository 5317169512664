.box-grid {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-areas: 'bo nært x' 'næring nært lev';
    grid-template-columns: auto 1fr auto;
    grid-row-gap: 2rem;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    max-width: 1200px;
    & h1 {
      margin-bottom: 1rem;
    }
    & > *:nth-child(1) {
      grid-area: nært;
      align-self: center;
      width: 140%;
      max-width: 500px;
      margin-left: -25%;
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
      & h1 {
        font-size: 8rem;
        line-height: 7rem;
        margin-bottom: 2rem;
      }
    }
    & > *:nth-child(2) {
      grid-area: bo;
      & h1 {
        font-size: 8rem;
        line-height: 8rem;
        margin-bottom: 2rem;
      }
    }
    & > *:nth-child(3) {
      grid-area: næring;
      & h1 {
        font-size: 4rem;
        line-height: 4rem;
        margin-bottom: 2rem;
      }
    }
    & > *:nth-child(4) {
      grid-area: lev;
      & h1 {
        font-size: 8rem;
        line-height: 8rem;
        margin-bottom: 2rem;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  @include media-breakpoint-down(md) {
    & > *:nth-child(1) {
      order: 2;
    }
  }
}
h1.post-category {
  margin-bottom: 0;
}
.dynamic-page-categories {
  width: 350px;
  text-align: left;
  margin-left: auto;
}
.categories-simple {
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
  &-horizontal {
    justify-content: center;
    gap: 5rem;
    @include media-breakpoint-down(sm) {
      gap: 3rem;
    }
    @include media-breakpoint-only(xs) {
      flex-direction: column;
      gap: 0;
    }
  }
}
.categories-link--small {
  font-size: 3rem;
}
@include media-breakpoint-up(lg) {
  .dynamic-page-categories {
    margin-top: -300px;
  }
}
